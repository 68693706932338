'use client'
import { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Formik, Form, ErrorMessage } from 'formik'
import axios from 'axios'
import { useRouter, useSearchParams } from 'next/navigation'
import { signIn } from 'next-auth/react'
import { useSession } from 'next-auth/react'
import { DocumentDuplicateIcon, EyeIcon } from '@heroicons/react/24/outline'
import StandardModal from './StandardModal'
import SocialAuthError from './LoginSocialError'

interface LoginFormProps {
  onSubmit?: (email: string, password: string) => void
}

interface CopyToClipboardProps {
  text: string
}

function CopyToClipboard({ text }: CopyToClipboardProps) {
  const [copied, setCopied] = useState(false)

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text)
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    } catch (err) {
      console.error('Failed to copy:', err)
    }
  }

  return (
    <button
      className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 text-gray-500 hover:bg-gray-50 sm:text-sm"
      onClick={copyToClipboard}
    >
      <DocumentDuplicateIcon className="h-5 w-5 text-blue-500" aria-hidden="true" />
      {copied ? <span className="ml-1 text-blue-500">Copied!</span> : null}
    </button>
  )
}

interface LoginFormProps {
  onSubmit?: (email: string, password: string) => void
  csrfToken: string | undefined
}

const LoginForm: React.FC<LoginFormProps> = ({ csrfToken, onSubmit }) => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const returnUrl = searchParams.get('return')
  const { data: session, status } = useSession()
  const [showPassword, setShowPassword] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [AppleModal, setAppleModal] = useState(false)

  const toggleAppleModal = () => {
    setAppleModal(true)
  }

  // https://tw-elements.com/docs/standard/components/social-buttons/

  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (status === 'authenticated') {
      router.push('/profile/work')
    }
  }, [status, router])

  if (!hasMounted) {
    return null
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const email = ''
    const password = ''
    const token = csrfToken

    toast.error('Your credentials have not been recognised')

    if (onSubmit) {
      onSubmit(email, password)
    }
  }

  return (
    <>
      <div className="mt-10 rounded-md bg-white pb-10 sm:mx-auto sm:w-full sm:max-w-[680px] sm:shadow">
        <div>
          <SocialAuthError />
          <div className="bg-white px-6 sm:rounded-lg sm:px-12 sm:pt-12">
            <h2 className="mb-6 text-center text-xl font-medium tracking-tight text-gray-900">
              Log in to your account
            </h2>
            {AppleModal && <StandardModal />}
            <div className="grid grid-cols-1 flex-col items-center gap-4 sm:flex sm:flex-row">
              <button
                onClick={() => {
                  // signIn('google', { callbackUrl: process.env.PROVIDER_GOOGLE_CALLBACK_URL as string })
                  console.log('returnUrl: ', returnUrl)
                  signIn('google', { callbackUrl: returnUrl ? returnUrl : '/profile/work' })
                }}
                className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
              >
                <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                  <path
                    d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                    fill="#EA4335"
                  />
                  <path
                    d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                    fill="#34A853"
                  />
                </svg>
                <span className="text-sm font-semibold leading-6">Google</span>
              </button>

              <button
                // onClick={() => {
                //   console.log(process.env.PROVIDER_GOOGLE_CALLBACK_URL)
                //   // formik.setSubmitting(true)
                //   signIn('google', { callbackUrl: process.env.PROVIDER_GOOGLE_CALLBACK_URL as string })
                // }}
                className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
              >
                <svg className="h-6 w-6" fill="gray-900" viewBox="0 0 56.693 56.693" aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M41.777 30.517c-.062-6.232 5.082-9.221 5.312-9.372-2.891-4.227-7.395-4.807-8.998-4.873-3.83-.389-7.477 2.256-9.42 2.256-1.939 0-4.941-2.199-8.117-2.143-4.178.062-8.029 2.43-10.179 6.17-4.339 7.527-1.11 18.682 3.118 24.791 2.067 2.986 4.532 6.346 7.766 6.223 3.117-.123 4.293-2.016 8.061-2.016s4.826 2.016 8.123 1.953c3.352-.061 5.477-3.043 7.527-6.041 2.373-3.469 3.35-6.828 3.408-6.998-.073-.034-6.534-2.509-6.601-9.95zM35.582 12.229c1.715-2.082 2.877-4.975 2.561-7.855-2.475.1-5.471 1.645-7.248 3.725-1.592 1.846-2.984 4.785-2.611 7.613 2.761.214 5.577-1.405 7.298-3.483z"
                  ></path>
                </svg>
                <span
                  className="text-sm font-semibold leading-6"
                  onClick={() => setAppleModal((prevState) => !prevState)}
                >
                  Apple
                </span>
              </button>
            </div>
            <div className="relative mt-10">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-white px-6 text-gray-900">Or login with your email</span>
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6"></div>
            </div>
          </div>
        </div>

        <div className="mt-8 grid grid-cols-1 bg-white px-6 sm:mx-auto sm:w-full sm:max-w-[800px] sm:rounded-lg sm:px-12 md:grid-cols-2">
          <Formik
            initialValues={{
              password: '',
              email: '',
              csrfToken: csrfToken,
            }}
            onSubmit={async (values, actions) => {
              const resp = await signIn('credentials', {
                redirect: false,
                password: values.password,
                email: values.email,
              })
              if (resp?.ok) {
                const url = returnUrl ? returnUrl : '/'
                router.push(url)
              } else {
                // console.log(resp)
                setShowErrorMessage(true)
                // ToDo: error
              }
            }}
          >
            {(formik) => (
              <Form className="space-y-6" action="#" method="POST">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      disabled={formik.isSubmitting}
                      required
                      tabIndex={0}
                      autoFocus
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                      Password
                    </label>
                    <div className="text-sm">
                      <a href="/reset-password" className="text-gray-600 hover:text-blue-500" tabIndex={3}>
                        Forgot password?
                      </a>
                    </div>
                  </div>
                  <div className="relative mt-2">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="current-password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      disabled={formik.isSubmitting}
                      required
                      tabIndex={1}
                      className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                    <button
                      type="button"
                      onClick={handleTogglePassword}
                      className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
                    >
                      <EyeIcon className="h-5 w-5 text-gray-600 hover:text-blue-500" aria-hidden="true" />
                    </button>
                  </div>
                  {showErrorMessage && <div className="mt-3 text-xs text-red-500">Password or email is invalid</div>}
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                    />
                    <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                      Remember me
                    </label>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    tabIndex={2}
                    className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    {formik.isSubmitting ? (
                      <>
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="mr-2 inline h-4 w-4 animate-spin text-gray-200 dark:text-gray-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#1C64F2"
                          ></path>
                        </svg>
                        Loading...
                      </>
                    ) : (
                      <>Sign in</>
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          <div className="ml-3 mt-6 flex-col space-y-6 px-6 sm:flex sm:px-6">
            <h2 className="mb-4 text-xl font-bold">Share the love</h2>
            <p className="mb-4 text-sm text-gray-600">
              Refer someone to Cleartrade who completes a transaction within 45 days. You will get $25.
            </p>

            <div>
              <label htmlFor="password" className="block flex text-xs font-semibold text-gray-900">
                Referral URL
              </label>
              <div className="mt-2 flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="referral"
                  id="referral-url"
                  className="block w-full min-w-0 flex-1 rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  defaultValue="cleartrade.com/referral/dfgh"
                  disabled
                />
                <CopyToClipboard text="cleartrade.com/referral/dfgh" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-12">
        <p className="mt-10 text-center text-sm text-gray-900">
          No account?{' '}
          <a href="/registration" className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
            Create your account for free
          </a>
        </p>
      </div>
      <ToastContainer />
    </>
  )
}

export default LoginForm
