import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { useSearchParams } from 'next/navigation'

export default function SocialAuthError() {
  const searchParams = useSearchParams()

  let error_message = searchParams.get('errors')

  if (error_message) {
    error_message = decodeURIComponent(error_message)
    error_message = error_message.replace(/[\[\]"]/g, '')
  }

  if (!error_message) {
    return null
  }

  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-700">We encountered an error.</h3>
          <div className="mt-2 text-sm text-red-700">
            <ul role="list" className="list-disc space-y-1 pl-5">
              <li>{error_message}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
